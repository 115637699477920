<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    ref="dropdown-nav-item-notifications"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="has_new_notification ? ' ' : ''"
        badge-classes="bg-danger"
        class="add-proposal-button-nav"
        icon="BellIcon"
        size="21"
        @click="readAllNotifications"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('notifications') }}
        </h4>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area m-0"
      tagname="li"
    >
    <div>
      <b-link
        v-for="notification, index in notifications"
        :key="index"
        @click="markViewed(notification)"
        :class="`position-relative d-flex align-items-center ${notification.viewed ? 'color-gray-font-notification' : 'text-black'}`"
        @mouseover="mouseOverAction(index, 'hover')"
        @mouseleave="mouseOverAction(index, 'leave')"
      >
        <b-media class="media-notification">
          <template #aside>
            <b-avatar
              size="50"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <div class="text-90-container">
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.subtitle }} 
              </span>
            </p>
            <p><span class="font-weight-bolder"></span> De <span class="font-weight-bolder">{{notification.proposal_title}}</span></p> 
            <small class="text-primary d-block">{{getFormatTimeDifference(notification.created_at)}}</small>
          </div>
        </b-media>
        <div class="dot-blue-viewed" v-if="!notification.viewed"></div>
        <div class="container-delete-notification d-none" :ref="`delete-icon-${index}`" @click.stop="deleteNotification(notification.uuid)">
          <feather-icon icon="XIcon" size="20"></feather-icon>
        </div>
      </b-link>
      <no-results v-if="notifications.length === 0" title="Sin notificaciones" subtitle="No tienes notificaciones" :show_button="false"/>

    </div>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, 
  BMedia, 
  BLink, 
  BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import notification_service from '@/services/notifications'
import { getFormatTimeDifference } from '@/libs/utils/times';

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue')
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      getFormatTimeDifference,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        suppressScrollX: true
      },
      has_new_notification: false,
      notifications: [],
    }
  },
  created () {
    notification_service.getNotifications().then(response => {
      this.formatNotifications(response.results)
    })

    this.$root.$on('add_push', (data) => {
      const ready_notification = this.notifications.find(noti => noti.uuid === data.notification_uuid)
      if (!ready_notification) {
        this.has_new_notification = true
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: data.title,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: data.body.message
            }
          })
        })
        const notification = {
          uuid: data.notification_uuid,
          meta: data.body,
          title: data.title,
          subtitle: data.body.message,
          type: 'light-success',
          icon: 'MessageSquareIcon',
          proposal_title: data.body.detail.title,
        }
        
        this.notifications.unshift(notification)
      }
    })
  },
  methods: {
    mouseOverAction(index, action) {
      if (action === 'hover') this.$refs[`delete-icon-${index}`][0].classList.remove('d-none');
      else this.$refs[`delete-icon-${index}`][0].classList.add('d-none');
    },
    deleteNotification(notification_uuid) {
      notification_service.deleteNotification(notification_uuid).then(() => {
        this.notifications = this.notifications.filter((notification) => notification.uuid !== notification_uuid);
      });
    },
    readAllNotifications() {
      this.has_new_notification = false
    },
    formatNotifications(notifications) {
      notifications.forEach(notification => {
        const ready_notification = this.notifications.find(noti => noti.uuid === notification.uuid)
        if (!ready_notification) {
          const obj = {
            uuid: notification.uuid,
            meta: notification.metadata,
            title: notification.title,
            subtitle: notification.body,
            type: 'light-success',
            icon: 'MessageSquareIcon',
            created_at: notification.created_at,
            viewed: notification.viewed,
            proposal_title: notification.metadata && notification.metadata.detail && notification.metadata.detail.title ? notification.metadata.detail.title : '',
          }

          this.notifications.push(obj)
        }
      });
    },
    markViewed(notification) {
      notification_service.markViewedNotification(notification.uuid).then(() => {

        if (notification.meta.type === 'chat_message') {
          const new_notifications = []
          this.notifications.forEach((noti) => {
            if (noti.uuid === notification.uuid) noti.viewed = true;
            new_notifications.push(noti)
          })
          this.notifications = new_notifications;
          this.$refs['dropdown-nav-item-notifications'].hide();
          this.$router.push({ name: 'chat-proposal', params: {chat_uuid: notification.meta.chat_uuid}});
          this.$root.$emit('update_chat_notification', notification.meta.chat_uuid);
        }
      })
    },
  },
  watch: {
  }
}
</script>
<style scoped>
.add-proposal-button-nav {
  color: white;
  padding: 9px;
  width: 2.8rem;
  height: 2.8rem;
}
.add-proposal-button-nav:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10em;
  cursor: pointer;
}
.text-90-container {
  display: block;
  width: 95%;
}
.container-delete-notification {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  color: #aaaaaa;
}
.container-delete-notification:hover {
  background-color: #f3f3f3;
  border-radius: 100%;
}
.dot-blue-viewed {
  height: 12px;
  width: 12px;
  background-color: #005cf9;
  border-radius: 10em;
  position: absolute;
  right: 1.5em;
}
.color-gray-font-notification, .color-gray-font-notification p, .color-gray-font-notification small {
  color: #bebebe !important;
}
</style>
